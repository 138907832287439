'use client';
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as Sentry from '@sentry/nextjs';
import { useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/navigation';
import Link from 'next/link';
import ory from '../../../pkg/sdk/frontend';
import Flow from '../../../pkg/components/Flow';
import { handleFlowError } from '../../../pkg/errors';
import Spinner from '../../../pkg/components/Spinner';
var Links = function (_a) {
    var flow = _a.flow;
    var t = useTranslation('login').t;
    var forgotPasswordURL = useMemo(function () {
        var search = new URLSearchParams();
        if (flow === null || flow === void 0 ? void 0 : flow.return_to) {
            search.set('return_to', flow.return_to);
        }
        else if (flow === null || flow === void 0 ? void 0 : flow.oauth2_login_challenge) {
            // the recovery flow doesn't support `login_challenge`, so we embed the
            // login_challenge in `return_to` instead.
            var search2 = new URLSearchParams();
            search2.set('login_challenge', flow.oauth2_login_challenge);
            search.set('return_to', "/login?".concat(search2.toString()));
        }
        return "/recovery?".concat(search.toString());
    }, [flow]);
    var registrationURL = useMemo(function () {
        var search = new URLSearchParams();
        if (flow === null || flow === void 0 ? void 0 : flow.return_to) {
            search.set('return_to', flow.return_to);
        }
        if (flow === null || flow === void 0 ? void 0 : flow.oauth2_login_challenge) {
            search.set('login_challenge', flow.oauth2_login_challenge);
        }
        return "/registration?".concat(search.toString());
    }, [flow]);
    if (!flow) {
        return null;
    }
    return (_jsx("div", { className: "flex flex-row justify-end gap-4", children: (flow === null || flow === void 0 ? void 0 : flow.refresh) || (flow === null || flow === void 0 ? void 0 : flow.requested_aal) !== 'aal1' ? (_jsx(Link, { className: "text-sm text-slate-600 hover:text-slate-400 font-semibold", href: "/logout", children: t('logout', { context: 'instead' }) })) : (_jsxs(_Fragment, { children: [!flow.oauth2_login_challenge && (_jsx(Link, { className: "text-sm text-slate-600 hover:text-slate-400 font-semibold", href: registrationURL, children: t('createAccount') })), _jsx(Link, { className: "text-sm text-slate-600 hover:text-slate-400 font-semibold", href: forgotPasswordURL, children: t('recoverAccount') })] })) }));
};
function LoginForm() {
    var _this = this;
    var searchParams = useSearchParams();
    var router = useRouter();
    var t = useTranslation('login').t;
    // optimistically handles flow errors
    var handleErr = useCallback(function (err) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, handleFlowError(err, router, 'login', setFlow)];
    }); }); }, [router]);
    var _a = useState(), flow = _a[0], setFlow = _a[1];
    var _b = {
        returnTo: searchParams.get('return_to'),
        flowID: searchParams.get('flow'),
        // Refresh means we want to refresh the session. This is needed, for
        // example, when we want to update the password of a user.
        refresh: searchParams.get('refresh'),
        // AAL = Authorization Assurance Level
        aal: searchParams.get('aal'),
        // Login Challenge is set when the page is used in an OIDC setting. The
        // OAuth2 *server side* API can be used to get details about the challenge,
        // including the name/id of the OAuth2 Client.
        loginChallenge: searchParams.get('login_challenge'),
    }, returnTo = _b.returnTo, flowID = _b.flowID, refresh = _b.refresh, aal = _b.aal, loginChallenge = _b.loginChallenge;
    useEffect(function () {
        // If we already have a flow, do nothing.
        if (flow) {
            return;
        }
        var getFlow = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, err_1, data, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!flowID) return [3 /*break*/, 6];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 5]);
                        return [4 /*yield*/, ory.getLoginFlow({ id: String(flowID) })];
                    case 2:
                        data = (_a.sent()).data;
                        setFlow(data);
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _a.sent();
                        return [4 /*yield*/, handleErr(err_1)];
                    case 4:
                        if (!(_a.sent())) {
                            // @TODO: Handle error
                            console.error(err_1);
                        }
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                    case 6:
                        _a.trys.push([6, 8, , 10]);
                        return [4 /*yield*/, ory.createBrowserLoginFlow({
                                refresh: Boolean(refresh),
                                aal: aal ? String(aal) : undefined,
                                returnTo: returnTo ? String(returnTo) : undefined,
                                loginChallenge: loginChallenge ? String(loginChallenge) : undefined,
                            })];
                    case 7:
                        data = (_a.sent()).data;
                        setFlow(data);
                        return [3 /*break*/, 10];
                    case 8:
                        err_2 = _a.sent();
                        return [4 /*yield*/, handleErr(err_2)];
                    case 9:
                        if (!(_a.sent())) {
                            // @TODO: Handle error
                            console.error(err_2);
                        }
                        return [3 /*break*/, 10];
                    case 10: return [2 /*return*/];
                }
            });
        }); };
        getFlow();
    }, [handleErr, router, flow, aal, flowID, refresh, returnTo]); // eslint-disable-line react-hooks/exhaustive-deps
    var onSubmit = function (values) { return __awaiter(_this, void 0, void 0, function () {
        var migration, err_3, apiErr;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    // This should be a "shallow" navigation
                    router.push("/login?flow=".concat(flow === null || flow === void 0 ? void 0 : flow.id));
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 6]);
                    return [4 /*yield*/, fetch('/api/migrateuser', {
                            method: 'POST',
                            body: JSON.stringify({
                                user: values.identifier,
                            }),
                        })];
                case 2:
                    migration = _b.sent();
                    if (!migration.ok) {
                        Sentry.captureException(new Error('/api/migrateuser failed'), {
                            tags: {
                                user: values.identifier,
                            },
                        });
                    }
                    return [4 /*yield*/, ory.updateLoginFlow({
                            flow: String(flow === null || flow === void 0 ? void 0 : flow.id),
                            updateLoginFlowBody: values,
                        })];
                case 3:
                    _b.sent();
                    if (flow === null || flow === void 0 ? void 0 : flow.return_to) {
                        window.location.href = flow.return_to;
                        return [2 /*return*/];
                    }
                    router.push('/');
                    return [3 /*break*/, 6];
                case 4:
                    err_3 = _b.sent();
                    return [4 /*yield*/, handleErr(err_3)];
                case 5:
                    apiErr = _b.sent();
                    if (!apiErr) {
                        // error has been handled, we're done
                        return [2 /*return*/];
                    }
                    // if the previous handler did not match the error it's most likely a form
                    // validation error
                    if (((_a = apiErr === null || apiErr === void 0 ? void 0 : apiErr.response) === null || _a === void 0 ? void 0 : _a.status) === 400) {
                        // Yup, it is!
                        setFlow(apiErr.response.data);
                        return [2 /*return*/];
                    }
                    // We still have an unhandled error – now we really have no idea
                    console.error('Completely unhandled error', err_3);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx("h1", { children: t('headline', {
                            context: refresh ? 'confirmAction' : aal ? '2fa' : 'login',
                        }) }), _jsx("p", { children: t('tagline') })] }), _jsx("div", { className: "flex flex-col gap-8", children: flow ? (_jsxs(_Fragment, { children: [_jsx(Flow, { onSubmit: onSubmit, flow: flow, context: "login" }), _jsx(Links, { flow: flow })] })) : (_jsx(Spinner, { className: "w-6 h-6" })) })] }));
}
export default LoginForm;
